.btn {
    font-family: var(--font-default-medium);
}

.btn__icon {
    font-size: calc(22em/16);
    margin-right: 5px;
    vertical-align: -.2em;
}

.btn-no-styling {
    border: none;
    background: none;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
    text-align: left;
}

.btn-no-styling.text-primary:hover,
.btn-no-styling.text-primary:focus {
    color: var(--color-primary-dark) !important;
}


.btn-outline-primary:hover {
    color: #fff
}

.btn-outline-white:hover {
    color: var(--color-text-default);
    background-color: white;
}

.btn-outline-dark:hover {
    color: white;
}