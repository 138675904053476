@media (min-width: 768px) {
    .image-page-heading {
        padding-bottom: calc(85rem/16);
    }

    .image-page-heading__img {
        margin-bottom: calc(-85rem/16);
    }
}

