.modal-tracking .modal-content {
    @media (min-width: 768px) {
        border-radius:1rem;
    }
}

@media (max-width: 767px) {
    .modal-tracking.fade .modal-dialog {
        position: absolute;
        left:0;
        right:0;
        bottom:0;
        margin: 0;
        transform: translateY(100%);
    }
    .modal-tracking.fade.show .modal-dialog {
        transform: translate(0);
    }
}


.modal-tracking .modal-content input::-moz-selection {
    color:var(--color-dark);
    background-color:#ffffff;
}
.modal-tracking .modal-content input::selection {
    color:var(--color-dark);
    background-color:#ffffff;
}


.modal-tracking .form-errors,
.modal-tracking .parsley-errors-list{
    position: absolute;
    left:0;
    right:0;
    top:110%;
    list-style: none;
    padding: 0;
    text-align: center;
    font-size: .625rem;

    @media (min-width: 768px) {
        font-size: .75rem;
    }
}

.form-control.form-control--tracking {
    height: 3.75rem;
    border-top-left-radius: 100px !important;
    border-bottom-left-radius: 100px !important;
    background: transparent;
    border:1px solid #fff;
    color:#fff;
    font-size: .9375rem;
    padding:1rem 1rem 1rem 3.5rem;

    @media (min-width: 768px) {
        font-size: 1rem;
    }
}
.form-control.form-control--tracking::placeholder {
    color:#fff;
}
.form-control--tracking__icon  {
    color:inherit;
    font-size: 1.5rem;
    position: absolute;
    left:1.25rem;
    top:50%;
    transform: translateY(-50%);
}

.modal-tracking__link {
    text-decoration: none;
    font-size: 1.125rem;
    font-family: var(--font-default-bold);

    @media (min-width: 768px) {
        font-size: 1.375rem;
    }
}
.modal-tracking__link .icon {
    transform: rotate(-135deg);
}