.info-widget__head {
    margin-bottom: 1.5rem;
    @media (min-width: 768px) {
        margin-bottom: calc(48rem/16);
    }
}
.info-widget__icon {
    font-size: calc(86rem/16);
    color: var(--color-primary);
}
.info-widget__img {
    width: 240px;
    max-width: 100%;
}

.info-widget__title {
    font-size: 2rem;
    @media (max-width: 767px) {
        font-size: 1.3rem
    }
}

.info-widget__sub-title {
    font-size: 26px;
    @media (max-width: 767px) {
        font-size: 1.3rem
    }
}