.accordion__head:not(:first-child) {
    display: block;
    margin-top: 4px;
}
.accordion__head-btn {
    padding: calc(12rem/16) calc(10rem/16);
    margin: 0;
    white-space: normal;
    word-break: break-word;
    color: var(--color-primary);
    background: var(--color-gray);
    border-radius: 0;
    text-align: left;
    font-size: 1.3rem;

    @media screen and (min-width: 768px) {
        font-size: calc(24rem/16);
        padding: calc(24rem/16) calc(19rem/16);
    }
}

.accordion--small .accordion__head-btn {
    padding: .5rem calc(10rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
        padding: .7rem 1rem;
    }
}
.accordion__body {
    background: var(--color-gray);
    display: block;
    overflow: hidden;
}
.accordion__body-inner {
    padding: 0 .75rem .625rem;
    @media screen and (min-width: 768px) {
        padding: 0 calc(19rem/16) calc(19rem/16);
    }
}
.accordion__collapse-icon {
    font-size: 1rem;
    transform: rotate(90deg) translateZ(0);
    transition: transform 90ms ease-out;
}
.collapsed .accordion__collapse-icon {
    transform: rotate(45deg);
}
.collapsed.accordion__head-btn {
    color: #6B716F;
}