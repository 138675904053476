.page-heading-img {
    position: relative;
}

.page-heading-img__body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    line-height: 1;
    padding-bottom: .5rem;
    @media (min-width: 768px) {
        padding: calc(45rem/16) calc(60rem/16);
    }
}

.page-heading-img__body:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 80px;
    opacity: .6;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFF 100%);
    z-index: -1;
    @media (min-width: 768px) {
        height: 250px;
    }
}
.page-heading-img__body--dark.page-heading-img__body:after {
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000 100%);
}

.page-heading-img__title {
    font-family: var(--font-default-medium);
    font-size: calc(23rem/16);
    @media (min-width: 768px) {
        font-size: calc(80rem/16);
        line-height: calc(100rem/16);
    }
}

.page-heading__intro {
    font-size: calc(20rem/16);
}

.page-heading-decoration:before {
    content: '';
    position: absolute;
    top: 0;
    right: -1000px;
    left: -10%;
    height: calc(475rem/16);
    background: var(--color-gray);
    z-index: -1;
    @media (max-width: 767px) {
        height: 10rem;
    }
}
