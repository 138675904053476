.slide {
    padding-top: calc(40rem/16);
    padding-bottom: calc(40rem/16);
}
.slide--md {
    padding-top: calc(20rem/16);
    padding-bottom: calc(20rem/16);
}

@media screen and (min-width: 768px) {
    .slide {
        padding-top: calc(100rem/16);
        padding-bottom: calc(100rem/16);
    }

    .slide--md {
        padding-top: calc(60rem/16);
        padding-bottom: calc(60rem/16);
    }
}