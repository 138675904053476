.console {
    position: fixed;
    z-index: 10;
    @media (max-width: 767px) {
        bottom: 2rem;
        left: 1rem;
        right: 1rem;
        height: calc(50rem/16);
        display: flex;
        justify-content: space-around;
        z-index: 12;
    }
    @media (min-width: 768px) {
        left: 0;
        top: 40%;
        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
        transform-origin: top left;
        transform: rotate(-90deg) translateX(-49%) translateZ(0);
    }
}


.btn-console {
    @media (max-width: 767px) {
        box-shadow: 0 4px 14px 0 rgba(0,0,0,0.3);
        padding: 6px 2rem;
    }
    @media (min-width: 768px) {
        font-size: calc(20rem/16);
        box-shadow: 0 0 calc(14rem / 16) 0 rgba(0,0,0,0.2);
        border-radius: 0 0 4px 4px;
        text-transform: uppercase;
        padding: calc(15rem/16) calc(25rem/16);
    }
}

@media (max-width: 767px) {
    .btn-console--round {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: calc(50rem/16);
    }

    .btn-console .icon-shipping {
        font-size: calc(26rem/16);
    }

}

.btn-console__icon {
    vertical-align: -0.3em;
    font-size: calc(26rem/16);
    @media (max-width: 767px) {
        font-size: calc(23rem/16);
    }
}

.btn-console .icon-arrow-medium-down {
    @media (max-width: 767px) {
        font-size: 11px;
        transform: rotate(180deg) translateY(3px);
    }
    @media (min-width: 768px) {
        font-size: 1rem;
        vertical-align: -.2em;
    }
}

@media (min-width: 768px) {
    .btn-console .icon-contact {
        transform: rotate(90deg);
    }
}