.collapse-list__btn {
    border-radius: 0;
    background-color: var(--color-gray);
    display: inline-flex;
    align-items: center;
    padding: 1rem 1.5rem;
    @media (min-width: 768px) {
        font-size: calc(24rem/16);
    }
}

.collapse-list__btn:after {
    content: var(--icon-arrow-left);
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: rotate(90deg);
    margin-left: auto;
    transition: transform 90ms ease-out;
}

.collapsed.collapse-list__btn:after {
    transform: rotate(270deg);
}

.collapse-list__list {
    padding: 0;
    border: 0;
    box-shadow: 0 2px 6px rgba(0,0,0,.2);
}

.collapse-list__item {
    display: block;
    padding: 1rem 1.5rem;
    color: var(--color-text-default);
    @media (min-width: 768px) {
        font-size: calc(24rem/16);
    }
}

.collapse-list__item:nth-child(2n) {
    background-color: var(--color-gray);
}

.collapse-list__item:hover,
.collapse-list__item:active {
    background-color: var(--color-white-dark);
}