.hero-slide {
    position: relative;
}

.hero-slide__body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    line-height: 1;
}

.hero-slide__body:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 250px;
    opacity: .6;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFF 100%);
    z-index: -1;
}
.hero-slide__body--dark.hero-slide__body:after {
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000 100%);
}

.hero-slide__title {
    font-family: var(--font-default-bold);
    font-size: calc(32rem/16);
    line-height: calc(100/80);
    @media (min-width: 768px) {
        font-size: calc(64rem/16);
    }
    @media (min-width: 1200px) {
        font-size: calc(80rem/16);
    }
}
.hero-slide__subtitle {
    font-family: var(--font-default-medium);
    font-size: calc(32rem/16);
}
