/* form steps */
.form-step {
    display: none;
}
.form-step.is-current {
    display: block;
}

/* select */
.select {
    position: relative;
}
.select > select {
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:after {
    font-family: "iconfont";
    content: var(--icon-arrow-left);
    speak: none;
    position: absolute;
    right: 1rem;
    bottom: calc(7rem/16);
    transform: rotate(270deg);
    pointer-events: none;
    font-size: calc(14rem / 16);
    color: var(--color-border-gray);
}

/* recaptcha */
@media (max-width: 767px) {
    .grecaptcha-badge.grecaptcha-badge {
        bottom:100px !important;
    }
}