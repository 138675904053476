@media (min-width: 768px) {
    .download-teaser__text {
        display: flex;
        flex-direction: column;
    }

    .download-teaser--big {
        font-size: calc(18rem/16);
    }

    .download-teaser--big .download-teaser__text {
        justify-content: space-around;
        padding-left: 35px;
    }

    .download-teaser--big .download-teaser__title {
        font-size: calc(42rem/16);
    }
}
