.box-teaser {
    transition: background-color .3s;
}

.box-teaser.js-go-to-link:hover {
    background: var(--color-gray-darker);
}

.box-teaser__content {
    padding: 30px 20px;
    @media (min-width: 768px) {
        padding: 100px 60px;
    }
}

.box-teaser__title {
    font-size: calc(24rem/16);
    margin-bottom: 1rem;
    line-height: 1.2;
    @media (min-width: 768px) {
        font-size: calc(40rem/16);
        margin-bottom: 2rem;
    }
}