.social-icon {
    display: flex;
    width: calc(48rem / 16);
    height: calc(48rem / 16);
    color: var(--color-primary);
    background: white;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    font-size: calc(24rem / 16);
    text-shadow: none;
    transition: color .25s, transform .25s ease-out;
    transform: perspective(1px) translateZ(0);
}

.social-icon.social-icon:hover,
.social-icon.social-icon:focus {
    transform: translateY(-5px);
    color: var(--color-primary-dark);
}