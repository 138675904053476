.icon-cta__middle-col {
    max-width: 70%;
}

.icon-cta__icon {
    font-size: calc(72rem/16);
    @media (max-width: 767px) {
        font-size: calc(60rem/16);
    }
}

.icon-cta__text {
    font-size: calc(32rem/16);
    @media (max-width: 767px) {
        font-size: calc(24rem/16);
    }
}