.blockquote {
    font-size: 1rem;
    @media (min-width: 768px) {
        font-size: calc(18rem/16);
    }
}

.blockquote__content {
    color: var(--color-primary);
    font-size: calc(24rem/16);
    line-height: calc(44/40);
    font-style: italic;
    @media (min-width: 768px) {
        font-size: calc(40rem/16);
    }
}

.blockquote__cite {
    font-style: normal;
    color: var(--color-dark);
}