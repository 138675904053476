.content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.content-block + .pimcore_area_content > .content-block {
    margin-top: calc(40rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(70rem/16);
    }
}

.content-block + .content-block.has-anchor-offset,
.pimcore_area_content + .pimcore_area_content > .content-block.has-anchor-offset,
.content-block + .pimcore_area_content > .content-block.has-anchor-offset {
    margin-top: calc(- var(--navbar-height) - 20rem/16 + 40rem/16);
    padding-top: calc(var(--navbar-height) + 20rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(- var(--navbar-height) - 20rem/16 + 70rem/16);
        padding-top: calc(var(--navbar-height) + 20rem/16);
    }
}

.pimcore_area_contact-slide.pimcore_area_contact-slide + .pimcore_area_contact-slide > .content-block {
    margin-top: 0;
}

/* this should remove the space between this (last) element and the page-footer */
#main > .content-block:last-child.content-block--no-footer-margin,
.pimcore_area_content:last-child .content-block.content-block--no-footer-margin {
    margin-bottom: -80px; /* length of page-wrapper bottom padding */
}


.has-anchor-offset {
    border-top: 1px solid transparent;
    margin-top: calc(- var(--navbar-height) - 20rem/16);
    padding-top: calc(var(--navbar-height) + 20rem/16);
    @media (max-width: 767px) {
        margin-top: calc(- var(--navbar-height-mobile) - 20rem/16);
        padding-top: calc(var(--navbar-height-mobile) + 20rem/16);
    }
}