@media (max-width: 767px) {
    .cookie-bar__detail-link + .cookie-bar__detail-link {
        margin: 0;
    }

    .cookie-modal .modal-content {
        padding: 0;
    }

    .cookie-bar__buttons.cookie-bar__buttons {
        margin-top: 0.25rem;
        text-align: right;
    }

    .cookie-bar.cookie-bar {
        padding: .5rem .75rem;
        line-height: 1.3;
    }
}

@media (min-width: 768px) {
    .cookie-modal__title {
        font-size: 2rem;
    }

}

.cookie-bar__text {
    font-size: calc(14rem/16);
}
