.list-vertical-lines {
    display: inline-block;
}

.list-vertical-lines > li {
    padding-bottom: 1.5rem;
    position: relative;
}

.list-vertical-lines > li:not(:last-child):after {
    content: '';
    height: 20px;
    width: 1px;
    position: absolute;
    bottom: 5px;
    left: 50%;
    background: var(--color-gray-medium);
}