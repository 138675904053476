html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}

html.is-scrolling-disabled,
html.is-scrolling-disabled body {
    overflow: hidden;


}
html.is-scrolling-disabled body {
    position: relative;

    @media screen and (max-width: 767px) {
        position: fixed;
        left: 0;
        right: 0;
    }
}

.container.container {
    max-width: calc(1520rem/16);
    width: 100%;
}

.container.container:not(.container--normal) {
    @media (min-width: 768px) and (max-width: 1640px) {
        margin-left: calc(60rem/16);
        width: calc(100% - 60rem/16);
    }
}

.page-wrapper {
    padding-top: var(--navbar-height);
    padding-bottom: calc(80rem/16);
    @media (max-width: 767px) {
        padding-top: var(--navbar-height-mobile);
    }
}