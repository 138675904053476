.overlay,
.sidebar {
    position: fixed;
    top: var(--navbar-height-mobile);
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transform: translateY(100%);
    transition: transform .3s, opacity 0s .3s, visibility 0s .3s;
    z-index: 1;
    display: block;
    background: #fff;
    padding: 2rem 1rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-shadow: 2px 0 14px 0 rgba(0,0,0,0.1);
    z-index: 11;

    @media screen and (min-width: 768px) {
        transform: translateX(-300px);
        padding: calc(176rem / 16) calc(32rem / 16) calc(32rem/16);
        top: var(--navbar-height);
        right: auto;
        width: 300px;
    }
}
.is-open.overlay,
.is-open.sidebar {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.3s;
}

@media (min-width: 768px) {
    .is-active.sidebar-toggle-btn {
        transform: translateY(300px);
        transition: transform 0.3s;
    }

    .sidebar-toggle-btn {
        transition: transform 0.3s;
    }
}

.overlay__close,
.sidebar__close {
    position: absolute;
    margin: 0;
    padding: calc(10rem / 16);
    top: 0;
    right: 0;
    font-size: calc(20rem / 16);
    z-index: 1;

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16);
    }
}