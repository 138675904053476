.slider {
    clear: both;
}

/* row--same-height */
.slider--same-height .slick-slide {
    display: flex;
    flex-grow: 1;
    height: auto;
    flex-direction: column;
}
/*
 * the .row--same-height selector has to be after
 * .row--same-height__item to slick-arrowincrease the specificity
 * in case of chaining the two selectors
 * (which is needed for nested rows)
 */
.slider--same-height.slick-slider .slick-track {
    display: flex;
    flex-direction: row;
}
.slider--same-height.slick-slider {
    display: block;
}

/* this div is created automatically by slick-slider */
.slider--same-height .slick-slide > div {
    display: flex;
    flex-grow: 1;
    height: auto;
}

.slider--same-height .slick-slide > div > .slider--same-height__item {
    flex-grow: 1;
    height: auto;
}


/* arrows */
.slider__arrow {
    position: absolute;
    left: 15px;
    top: 50%;

    transform: translateY(-50%);
    background: rgba(255,255,255,.4);
    border-radius: 4px;
    border: none;

    font-size: calc(30rem/16);
    color: var(--color-dark);
    z-index: 2;
    line-height: 1;
    padding: 10px;
    margin: -10px;

    @media screen and (min-width: 768px){
        left: 44px;
    }
}


.slider__arrow:focus {
    outline: none;
    color: var(--color-primary-dark);
}

.slider__arrow.slider__arrow--small{
    font-size: calc(18rem/16);
}

.slider__arrow.slick-next {
    left: auto;
    right: 15px;

    @media screen and (min-width: 768px) {
        left: auto;
        right: 30px;
    }
    @media screen and (min-width: 1200px) {
        right: 44px;
    }
}

.hero-slide .slider__arrow.slick-prev {
    @media (min-width: 768px) {
        left: 74px;
    }
}

.slider__arrow.slick-disabled {
    opacity: .1;
}

.slick-vertical.slick-vertical .slick-prev,
.slick-vertical.slick-vertical .slick-next {
    left: 50%;
    transform: translateX(-50%);
    right: auto;
}

.slick-vertical .slick-prev {
    top: -10px;
}

.slick-vertical .slick-next {
    top: auto;
    bottom: -10px;
}

/* add a margin to vertical sliders so the arrows have more space */
.slick-vertical {
    margin-top: 20px;
    margin-bottom: 20px;
}

.slick-vertical .slider__arrow.slick-prev .icon {
    transform: rotate(90deg);
}

.slick-vertical .slider__arrow.slick-next .icon {
    transform: rotate(270deg);
}

.slider__arrow__icon {
    vertical-align: middle;
}

.slick-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0;
}
@media screen and (max-width: 767px) {
    .hero-img-slider .slick-dots {
        bottom: auto;
        top:0;
        margin-top: 75%;
    }
}
.slick-dots > li {
    float: none;
    display: inline-block;
}
.slick-dots__btn {
    position: relative;
    margin: 0 5px;
    padding: 0;
    color: #fff;
    background: none;
    border: 0;
    width: 30px;
    overflow: hidden;
}
.slick-dots__btn__text {
    font-size: 14px;
    margin-bottom: 5px;
    transform: translateY(100%);
    opacity: 0;
    display: block;
    position: relative;
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
}
.slick-active .slick-dots__btn__text {
    transform: translateY(0);
    opacity: 1;
}
.slick-dots__btn:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255,255,255, .7);
    height: 3px;
    transition: transform 200ms ease-in-out, background 200ms ease-in-out;
    transform-origin: bottom center;
    transform: translateZ(0) scaleY(1);
}
.slick-active > .slick-dots__btn:after {
    background: var(--color-primary);
    transform: translateZ(0) scaleY(2);
}
.slick-dots__btn:focus {
    outline: none;
}
.slick-dots__btn:focus:after {
    background: var(--color-primary);
}

/* dots */
.slider--hide-dots .slick-dots {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

/* slick dots as inline list*/

.slick-dots-list {
    padding-left: 0;
    list-style: none;
}

.slick-dots-list > li {
    display: inline-block;
}

.slick-dots-list > li:not(:last-child) {
    margin-right: 3rem;
}

.slick-dots-list .slick-active {
    color: var(--color-primary);
}

/*slider arrows outside*/
.slider--arrows-outside .slider__arrow.slick-prev {
    @media (min-width: 768px) {
        left: 15px;
    }

    @media screen and (min-width: 1400px) {
        left: -30px;
    }

    @media screen and (min-width: 1500px) {
        left: -60px;
    }
}
.slider--arrows-outside .slider__arrow.slick-next {
    @media (min-width: 768px) {
        right: 15px;
    }
    @media screen and (min-width: 1400px) {
        right: -30px;
    }

    @media screen and (min-width: 1500px) {
        right: -60px;
    }
}

/* Slick dots primary */

.slick-dots-primary{
    list-style: none;
    padding-left: 0;
    text-align: center;
    line-height: 1;
}

.slick-dots-primary>li{
    display: inline-block;
    margin: 0 calc(4rem/16);
    width: calc(8rem/16);
    height: calc(8rem/16);
}

.slick-dots-primary>li button{
    background: none;
    color: transparent;
    background: var(--color-primary);
    opacity: 0.4;
    width: calc(8rem/16);
    height: calc(8rem/16);
    padding: 0;
    border-radius: 50%;
    line-height: 1;
    cursor: pointer;
    transition: all 150ms ease;
    box-shadow: none;
    border: none;
    display: block;
}

.slick-dots-primary>li button:hover{
    background: var(--color-primary);
    opacity: 1;
}

.slick-dots-primary>li.slick-active button{
    background: var(--color-primary);
    line-height: 1;
    opacity: 1;
}

@media (max-width: 768px) {
    .slider--arrows-top .slider__arrow {
        top: 23vw;
        transform: none;
    }

}
