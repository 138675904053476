@media screen and (min-width: 768px) {
    .lg-next, .lg-prev {
        display: none !important;
    }
    .lg-show-in .lg-toolbar {
        position: relative;
        height: 100%;
        pointer-events: none;
    }
    .lg-toolbar .lg-icon {
        pointer-events: auto;
        color: var(--color-white);
        font-family: 'iconfont' !important;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    }
    .lg-toolbar .lg-zoom-in,
    .lg-toolbar .lg-zoom-out {
        position: fixed;
        left:50%;
        top:50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 5rem;
        color: var(--color-white);
    }
    .lg-toolbar .lg-close:after {
        content: var(--icon-close);
    }
    .lg-toolbar .lg-zoom-in:after,
    .lg-toolbar .lg-zoom-out:after {
        content: var(--icon-search);
    }
    .lg-actions .lg-prev:after {
        content: var(--icon-arrow-left);
    }
    .lg-actions .lg-next {
        transform: scaleX(-1);
    }
    .lg-actions .lg-next:before {
        content: var(--icon-arrow-left);
    }
    .lg-backdrop.in {
        opacity: .75;
    }


    .lightbox-item {
        cursor: pointer;
    }
    .lightbox-item:after {
        font-family: iconfont;
        content: var(--icon-search);
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 3.5rem;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
        color: var(--color-white);
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 2;
    }
    .lightbox-item:hover:after {
        opacity: 1;
    }
}