@media screen and (max-width: 767px) {
    .text-with-media__media.text-with-media__media {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 768px) {
    .text-with-media {
        overflow: hidden;
    }
    .text-with-media__media-container {
        float: right;
        margin-left: 30px;
        max-width: 48%;

    }
    .text-with-media__media {
        display: block;
        margin-bottom: 10px;
        max-width: 100%;
        height: auto;
    }
    .text-with-media--media-left .text-with-media__media-container {
        float: left;
        margin-right: 30px;
        margin-left: 0;
    }
    .text-with-media__media.text-with-media__media--video {
        width: 496px;
        height: auto;
    }
}