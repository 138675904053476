.navbar-main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 12;
    padding: 0;
    background-color: #FFFFFF;
    box-shadow: 0 1px 1px 0 rgba(107,113,111,0.15);
    padding-top: calc(7rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) 0;
        height: calc(98rem / 16);
    }
}
.navbar-main > .navbar-collapse {
    @media screen and (min-width: 768px) {
        padding: 0 calc(10rem / 16);
        position: relative;
        height: 100%;
    }
    @media screen and (min-width: 1200px) {
        padding: 0 calc(30rem / 16);
    }
}
.navbar-nav {
    position: static;
    align-items: center;
    @media (min-width: 1200px) {
        padding-left: calc(10rem / 16);
    }
}
.navbar-nav:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: calc(60rem / 16);
    width: calc(1rem / 16);
    background: #fff;
    transition: background 250ms ease-in-out;
}
.navbar-nav > li {
    padding: 0;
    position: relative;

    @media screen and (min-width: 768px) {
        padding: 0 calc(2rem / 16);
    }
}
.navbar-nav > li > a {
    transition: color .3s;
    padding: calc(10rem / 16);
    display: inline-block;
    position: relative;
    margin: 0 calc(5rem/16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(6rem / 16);
        font-size: calc(14rem / 16);
        margin: 0 calc(4rem/16);
    }
    @media screen and (min-width: 1100px) {
        padding: calc(20rem / 16) calc(12rem / 16);
        margin: 0 calc(5rem/16);
    }
    @media screen and (min-width: 1400px) {
        padding: calc(20rem / 16) calc(12rem / 16);
        font-size: calc(18rem / 16);
    }
}
.navbar-nav li.active > a {
    color: var(--color-primary);
}
.navbar-brand {
    height: auto;
    padding: 0;
    margin: 0;
    line-height: 1;

    @media screen and (min-width: 768px) {
        margin-left: calc(20rem / 16);
    }

    @media screen and (min-width: 1400px) {
        margin-left: calc(45rem / 16);
    }
}
.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus {
    color: var(--color-primary);
}
.navbar-brand__logo {
    width: calc(150rem / 16);
    height: auto;

    @media screen and (min-width: 1200px) {
        width: calc(230rem / 16);
    }
}

/* navbar right */
.navbar-right__links {
    position: relative;
    padding-right: calc(20rem / 16);

    @media screen and (min-width: 1400px){
        padding-right: calc(50rem / 16);
    }
}
.navbar-right__links:after {
    content: '';
    position: absolute;
    right: calc(1rem / 16);
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    background: #fff;
    height: calc(60rem / 16);
    transition: background 250ms ease-in-out;
}
.navbar-right__links li:not(:last-child){
    margin-right: calc(15rem / 16);

    @media screen and (min-width: 1400px){
        margin-right: calc(42rem / 16);
    }
}

@media screen and (max-width: 1400px) {
    .navbar-button {
        font-size: calc(12rem/16);
        padding: 0.375rem 1rem;
    }

}