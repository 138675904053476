.billboard {
    position: relative;
    padding: 110px 0;
}

.billboard__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    opacity: .15;
}