.simple-accordion__head:not(:first-child) {
    display: block;
    margin-top: 4px;
}
.simple-accordion__body {
    display: block;
    overflow: hidden;
    color: #262626;

}
.simple-accordion__collapse-icon {
    font-size: calc(10rem/16);
    transform: rotate(180deg);
    transition: transform .2s ease-out;
}
.collapsed .simple-accordion__collapse-icon {
    transform: rotate(0deg);
}
.simple-accordion__head-btn.collapsed{
    color: var(--color-gray-medium);
}
.simple-accordion__head-btn {
    color: var(--color-primary);
    transition: color .2s;
}