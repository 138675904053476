.location-map__country,
.location-map__country path {
    fill: var(--color-primary) !important;
}

.location-map__country.active,
.location-map__country.active path,
.location-map__country:hover,
.location-map__country:hover path {
    cursor: pointer;
    fill: var(--color-primary-dark) !important;
}