.img-teaser__img {
    margin-bottom: 1rem;
    @media (min-width: 768px) {
        margin-bottom: 2rem;
    }
}

.img-teaser__title {
    font-size: 1rem;
    @media (min-width: 768px) {
        font-size: 2rem;
    }
}

.img-teaser__category {
    font-size: calc(14rem/16);
}