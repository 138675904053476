@media screen and (max-width: 767px) {
    html.has-overflow-hidden, .has-overflow-hidden body {
        overflow: hidden;
    }
    .navbar-collapse {
        position: absolute;
        height: calc(100vh - calc(80rem / 16));
        background: #fff;
        color: var(--color-text-default);
        left: 0;
        right: 0;
        bottom: 0;
        top: calc(80rem / 16);
        transform: translateX(-100%);
        transition: transform 250ms ease-in-out;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling:touch;
        padding-bottom: 5.5rem;
    }
    .navbar-main {
        height: calc(80rem/16);
        font-size: calc(19rem/16);
    }
    .is-open.navbar-main {
        background: #fff;
    }
    .is-open .navbar-collapse {
        transform: translateX(0);
    }
    .is-open .navbar__icon-link {
        color: var(--color-text-default);
    }
    .navbar__icon-link {
        line-height: calc(22rem/16);
    }
    .navbar__language-switch {
        font-size: calc(22rem/16);
    }
    .navbar-nav {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        margin-top: .5rem;
    }
    .navbar-nav > .nav-item + .nav-item {
        margin-top: 1rem;
    }

    .navbar-nav li a {
        font-size: 1.8rem;
        display: block;
        position: relative;
        color: var(--color-text-default);
    }

    .navbar-nav li.is-open > a {
        color: var(--color-primary);
    }

    .navbar-main__dropdown {
        padding: 0 1rem;
        margin-left: 1rem;
    }

    .navbar-main__dropdown__container li {
        border-top: calc(1rem / 16) solid #fff;
    }

    /* toggle button */
    .navbar-toggler {
        width: calc(60rem / 16);
        height: calc(64rem / 16);
        text-align: center;
        font-size: 0;
        position: relative;
        margin-top: -3px;
    }
    .navbar-toggler:focus {
        outline: none;
    }
    .navbar-toggler__bar {
        display: inline-block;
        width: calc(25rem / 16);
        height: calc(2rem / 16);
        background: var(--color-text-default);
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out, color 250ms ease-in-out;
    }
    .navbar-toggler__bar:nth-child(2) {
        margin-left: 5px;
    }
    .navbar-toggler__bar + .navbar-toggler__bar {
        margin-top: calc(5rem / 16);
    }

    .is-open .navbar-toggler__bar{
        background: var(--color-text-default);
    }
    .is-open .navbar-toggler__bar:nth-child(2) {
        opacity: 0;
    }
    .is-open .navbar-toggler__bar:nth-child(1) {
        transform: rotate(-45deg) translateX(calc(-5rem / 16)) translateY(calc(5rem / 16));
    }
    .is-open .navbar-toggler__bar:nth-child(3) {
        transform: rotate(45deg) translateX(calc(-5rem / 16)) translateY(calc(-5rem / 16));
    }

    .navbar-nav__toggle {
        position: absolute;
        padding: calc(15rem / 16);
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        font-size: calc(16rem / 16);
        transition: transform .3s ease-out;
    }
    li.is-open > a .navbar-nav__toggle {
        transform: translateY(-50%) rotate(90deg);
    }
    .navbar-main__dropdown,
    .navbar-main__dropdown__subnav {
        display: none;
    }
    .navbar-main__dropdown ul > li:not(:last-child) {
        border-bottom: calc(1rem / 16) solid #fff;
    }
    .navbar-main__dropdown li a {
        font-size: calc(18rem / 16);
        padding: calc(8rem / 16);
        display: block;
        position: relative;
    }
    .navbar-main__dropdown__subnav {
        border-top: calc(1rem / 16) solid #fff;
    }
    .navbar-main__dropdown__subnav li a {
        padding: calc(10rem / 16) calc(25rem / 16);
    }
    .navbar-nav li.is-open .navbar-main__dropdown,
    .navbar-main__dropdown li.is-open .navbar-main__dropdown__subnav {
        display: block;
    }

    /* navbar brand */
    .navbar-brand {
        padding-right: calc(15rem / 16);
        margin-left: auto;
    }
}