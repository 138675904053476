.icon-lang {
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url(/static/build/img/flags/sprite.svg);
    box-shadow: 0 0 5px rgba(0,0,0,.4);
    border-radius: 100%;
}
.icon-lang-pl {
    width: 20px;
    height: 20px;
    background-position: 0 0;
}
.icon-lang-bg {
    width: 20px;
    height: 20px;
    background-position: -36px 0;
}
.icon-lang-be {
    width: 20px;
    height: 20px;
    background-position: -72px 0;
}
.icon-lang-cs {
    width: 20px;
    height: 20px;
    background-position: -108px 0;
}
.icon-lang-de {
    width: 20px;
    height: 20px;
    background-position: -144px 0;
}
.icon-lang-en {
    width: 20px;
    height: 20px;
    background-position: -180px 0;
}
.icon-lang-es {
    width: 20px;
    height: 20px;
    background-position: -216px 0;
}
.icon-lang-hr {
    width: 20px;
    height: 20px;
    background-position: -252px 0;
}
.icon-lang-hu {
    width: 20px;
    height: 20px;
    background-position: -288px 0;
}
.icon-lang-it {
    width: 20px;
    height: 20px;
    background-position: 0 -36px;
}
.icon-lang-ro_MD {
    width: 20px;
    height: 20px;
    background-position: -36px -36px;
}
.icon-lang-mk {
    width: 20px;
    height: 20px;
    background-position: -72px -36px;
}
.icon-lang-ro {
    width: 20px;
    height: 20px;
    background-position: -108px -36px;
}
.icon-lang-sr {
    width: 20px;
    height: 20px;
    background-position: -144px -36px;
}
.icon-lang-ru {
    width: 20px;
    height: 20px;
    background-position: -180px -36px;
}
.icon-lang-sl {
    width: 20px;
    height: 20px;
    background-position: -216px -36px;
}
.icon-lang-sk {
    width: 20px;
    height: 20px;
    background-position: -252px -36px;
}
.icon-lang-uk {
    width: 20px;
    height: 20px;
    background-position: -288px -36px;
}