@media (min-width: 768px) {
    .triple-slider-item, .triple-slider .slick-slide {
        width: 750px;
    }

    .triple-slider-item {
        padding-right: 35px;
        padding-left: 35px;
    }

    .triple-slider .slider__arrow {
        top: 175px;
        left: 50%;
        transform: translateX(-375px);
    }

    .triple-slider .slider__arrow.slick-next {
        left: auto;
        right: 50%;
        transform: translateX(375px);
    }

}
.triple-slider-item__img {
    opacity: .5;
    transition: opacity .5s ease;
}

.slick-current .triple-slider-item__img {
    opacity: 1;
}


.triple-slider-item__title {
    font-size: calc(20rem/16);
    margin-bottom: 2rem;
    @media (min-width: 768px) {
        font-size: calc(40rem/16);
    }
}

.triple-slider-item__content {
    margin-top: 1.5rem;
    opacity: 0;
    transition: opacity .5s ease;
    @media (min-width: 768px) {
        margin-top: 3rem;
    }
}

.slick-current .triple-slider-item__content {
    opacity: 1;
}