body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
}

b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    font-family: var(--font-default-medium);
    color: var(--color-headlines);
}

h1, .h1 {
    font-size: calc(70rem/16);
}
h2, .h2 {
    font-size: calc(60rem/16);
}
h3, .h3 {
    font-size: calc(50rem/16);
}
h4, .h4 {
    font-size: calc(24rem/16);
    font-family: var(--font-default-bold);
}
h5, .h5 {
    font-size: calc(18rem/16);
}
h6, .h6 {
    font-size: 1em;
}

@media (max-width: 767px) {
    h1, .h1 {
        font-size: calc(32rem/16);
    }
    h2, .h2 {
        font-size: calc(28rem/16);
    }
    h3, .h3 {
        font-size: calc(25rem/16);
    }
    h4, .h4 {
        font-size: calc(22rem/16);
        font-family: var(--font-default-bold);
    }
    h5, .h5 {
        font-size: calc(18rem/16);
    }
    h6, .h6 {
        font-size: 1em;
    }

}

hr {
    border-top: 1px solid var(--color-gray-medium);
    opacity: .5;
}