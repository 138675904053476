.form-errors,
.parsley-errors-list {
    color: var(--color-danger);
}
.form-errors:empty {
    display: none;
}
.form-errors ul,
.parsley-errors-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.select.has-error:after {
    bottom: calc(31rem/16);
}