/*Margin Helper*/


/*Padding Helper*/


/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.font-default {
    font-family: var(--font-default);
}

.font-medium {
    font-family: var(--font-default-medium);
}

/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-gray {color: var(--color-gray-medium);}
.text-headline {color: var(--color-headlines);}

.text-hover-primary:hover,
.text-hover-primary:focus {
    color: var(--color-primary);
}
/*Background Color Helper*/
.bg-gray,
.bg-extended:before {
    background-color: var(--color-gray);
}

.bg-extended--left {
    position: relative;
}

@media (max-width: 767px) {
    .bg-extended {
        padding-left: 15px;
        padding-right: 15px;
    }

}

@media (min-width: 768px) {
    .bg-extended:before {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        width: 10000px;
    }

    .bg-extended.bg-extended--right:before {
        right: auto;
        left: 100%;
    }
}

.bg-image-cover {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

/* other helpers*/
.overflow-hidden {
    overflow: hidden;
}

.position-relative {
    position: relative;
}

.icon-in-text {
    vertical-align: -.16em;
}

.js-go-to-link {
    cursor: pointer;
}

.increased-click-area.increased-click-area {
    padding: 10px;
    margin: -10px;
}

.break-word {
    word-break: break-word;
}

.embed-responsive--svg-container svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}