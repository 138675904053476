.footer {
    padding: 3rem 0 11rem;
    @media (min-width: 768px) {
        padding-top: calc(70rem/16);
        padding-bottom: 2rem;
        font-size: calc(20rem/16);
        background-repeat: no-repeat;
        background-image: url(/static/build/img/footer-bg.svg);
        background-size: auto 450px;
        background-position: bottom left;
    }
}

.footer-links > li {
    margin-left: calc(45rem/16);
    margin-right: calc(45rem/16);
    text-shadow: 2px 0 5px var(--color-primary);

    @media (max-width: 767px) {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.footer__bottom {
    padding-top: 2rem;
    margin-top: 3rem;
    @media (max-width: 767px) {
        padding-top: 3rem;
        border-top: 1px solid white;
    }
}

.footer-links a:not(.btn):hover {
    color: var(--color-white-dark);
}

