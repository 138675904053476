.wysiwyg {
    line-height: calc(32/18);
}

.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 1.5em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}

.wysiwyg ul{
    list-style: none;
    padding: 0;
}

.wysiwyg ul > li{
    padding-left: 20px;
    position: relative;
    margin-bottom: 7px;
    line-height: 22px;
    /* 'clearfix' for floating */
    overflow: hidden;
}

.wysiwyg ul > li:before{
    content: '';
    width: calc(10rem/16);
    height: calc(10rem/16);
    background: var(--color-primary);
    margin-left: calc(-20rem/16);
    margin-right: calc(10rem/16);
    display: inline-block;
}

.wysiwyg table{
    max-width: 100%;
    border: 1px solid white;
}

.wysiwyg table th {
    background: var(--color-primary);
    color: white;
}

.wysiwyg table td,
.wysiwyg table th {
    padding: .5rem 1rem;
    border: none;
}

.wysiwyg table td:not(:last-child),
.wysiwyg table th:not(:last-child) {
    border-right: 2px solid white;
}

.wysiwyg table tr:nth-child(2n) td {
    background: var(--color-gray);
}

.wysiwyg table tr:nth-child(2n+1) td {
    background: #dee2e6;
}