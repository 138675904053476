.video-js .vjs-big-play-button {
    border-radius: 50%;
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: background 250ms ease-in-out;
    background: rgba(255,255,255,.7);
    border: none;
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(30rem / 16);
    height: auto;
    width: auto;
    color: var(--color-primary);
}
.video-js .vjs-big-play-button:focus, .video-js:hover .vjs-big-play-button {
    background: rgba(255,255,255,1);
    transition: background 250ms ease-in-out;
}