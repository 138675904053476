.modal {
    -webkit-overflow-scrolling: touch;
}

.modal-content {
    border-radius: 0;
    border: 0;
    font-size: calc(17rem / 16);
    position: relative;
    padding: calc(32rem/16) calc(20rem/16) calc(20rem/16);
}

.modal-md .modal-content {
    @media screen and (min-width: 768px) {
        padding: calc(60rem / 16) calc(70rem / 16) calc(40rem / 16);
    }
    @media screen and (min-width: 1200px) {
        padding: calc(80rem / 16) calc(100rem / 16) calc(50rem / 16);
    }
}

.modal-lg .modal-content {
    @media screen and (min-width: 768px) {
        padding: calc(100rem / 16) calc(80rem / 16) calc(60rem / 16);
    }
    @media screen and (min-width: 1200px) {
        padding: calc(125rem / 16) calc(110rem / 16) calc(60rem / 16);
    }
}

.modal-body__title {
    font-size: calc(28rem / 16);
    margin-bottom: calc(32rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(54rem / 16);
    }
}

.modal-body__title--small {
    font-size: calc(20rem / 16);
    margin-bottom: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
    }
}

/* close icon */
.modal .modal-content__close {
    position: absolute;
    margin: 0;
    padding: 1rem;
    top: 0;
    right: 0;
    font-size: calc(20rem / 16);
    z-index: 1;

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16);
    }
}

.modal-content.bg-primary .modal-content__close:hover,
.modal-content.bg-primary .modal-content__close:focus,
.modal-content.bg-primary .modal-content__close  {
    color:#fff;
}

.modal-dialog {
    max-width: calc(700rem/16);
}

/* medium modal */
.modal-dialog.modal-md {
    max-width: calc(1000rem / 16);
}

/* large modal */
.modal-dialog.modal-lg {
    max-width: calc(1264rem / 16);
}