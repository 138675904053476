.navbar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transform: translateY(30px);
    transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
    z-index: 1;
    display: block;
    background: #fff;
    padding: 1rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 1px 1px 0 rgba(107,113,111,0.15);

    @media screen and (min-width: 768px) {
        padding: calc(85rem / 16);
    }
}
.is-open.navbar__overlay {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition: transform 0.4s, opacity 0.4s;
}

.navbar__search__submit {
    font-size: calc(24rem / 16);
    line-height: 1;
    color: var(--color-gray-medium);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
    }
}
.navbar__search__input {
    font-size: calc(32rem / 16);
    line-height: 1;
    padding: calc(5rem / 16) calc(14rem / 16);
    display: block;
    width: 100%;
    color: var(--color-text-default);
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid transparent;
    transition: background-color 200ms ease-in-out;
    font-family: var(--font-default-medium);
    font-weight: 400;

    @media screen and (min-width: 768px) {
        font-size: calc(42rem / 16);
        padding: calc(10rem / 16) calc(24rem / 16);
    }
}
.navbar__search__input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: var(--color-gray-medium);
}
.navbar__search__input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: var(--color-gray-medium);
    opacity:  1;
}
.navbar__search__input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-gray-medium);
}
.navbar__search__input:focus {
    outline: none;
}

.navbar__close {
    width: calc(50rem/16);
    height: calc(50rem/16);
    text-align: center;
}