.contact {
    font-family: var(--font-default-medium);
    line-height: calc(27/18);
}

.contact__img {
    width: 100px;
}

.contact__name {
    color: var(--color-primary);
    font-size: calc(24rem/16);
}

/* larger contact */

.contact--lg .contact__img {
    width: 150px;
}

.contact--lg .contact__name {
    color: var(--color-primary);
    font-size: calc(28rem/16);
    @media (min-width: 768px) {
        font-size: calc(32rem/16);
    }
}
