.navbar-main__dropdown {
    display: none;
    padding: calc(45rem / 16);
    top: 100%;
    background: white;

    @media screen and (min-width: 768px) {
        position: absolute;
        min-width: calc(220rem / 16);
        box-shadow: 0 4px 14px 0 rgba(0,0,0,0.2);
        left: 50%;
        transform: translateX(-50%);
        padding: calc(40rem / 16) calc(50rem / 16);
        font-size: 1rem;
    }
}
.navbar-nav li.is-open .navbar-main__dropdown {
    display: block;
}
@media screen and (min-width: 768px) {
    .navbar-nav li:hover .navbar-main__dropdown {
        display: block;
    }
    .navbar-nav li.has-subnav > a:before,
    .navbar-nav li.has-subnav > a:after  {
        content: '';
        border-style: solid;
        border-width: 0 calc(10rem / 16) calc(12rem / 16) calc(10rem / 16);
        border-color: transparent transparent #fff transparent;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateY(-100%) translateX(-50%);
        z-index: 1;
        opacity: 0;
    }
    .navbar-nav li.has-subnav > a:after {
        border-width: 0 calc(12rem / 16) calc(14rem / 16);
        border-color: transparent transparent rgba(0, 0, 0, 0.07);
        z-index: 0;
    }
    .navbar-nav li:hover > a:before,
    .navbar-nav li:hover > a:after {
        opacity: 1;
    }
    .navbar-main__dropdown li:not(:first-child) {
        margin-top: 22px;
    }
}
.navbar-main__dropdown li a {
    display: block;
    @media screen and (min-width: 768px) {
        white-space: nowrap;
    }
}
.navbar-main__dropdown a:hover {
    color: var(--color-primary);
}

/* keep first dropwdown in the viewport for smaller displays */
@media (min-width: 768px) and (max-width: 1700px) {
    .navbar-main .nav-item:first-child .navbar-main__dropdown {
        left: 0px;
        transform: none;
    }
}