.history-slider__nav .active {
    transition: color .3s;
    color: var(--color-primary);
}

.history-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    text-align: center;
    position: relative;
    margin-bottom: 30px;
    @media (min-width: 768px) {
        margin-bottom: 50px;
        min-height: 170px;
    }

}

.history-item__year {
    font-size: calc(80rem/16);
    color: var(--color-gray-medium);
    opacity: .4;
    line-height: 1;
    @media (min-width: 768px) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        font-size: calc(200rem/16);
    }
}

.history-item__text {
    /* ie fix */
    width: 100%;

    padding-bottom: 1rem;
    font-size: calc(18rem/16);

    line-height: calc(28/24);

    color: var(--color-primary);
    @media (min-width: 768px) {
        font-size: calc(24rem/16);
    }
}

.history-item__text p {
    margin-bottom: .25rem;
}

.history-item__text a {
    text-decoration: underline;
}

.history-item__text a:hover {
    color: var(--color-primary-dark);
}

.history-slider .slick-list {
    padding: 30px 0;
}

@media (min-width: 768px) {



.history-slider:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 30px;
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
    pointer-events: none;
    z-index: 1;
}

.history-slider:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
    pointer-events: none;
}
}